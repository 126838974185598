import React from 'react';
import Layout from '../components/layout/Layout';
import SEO from "../components/seo";

const NotFound = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Not Found</h1>
    <p>You just hit a page that doesn&#39;t exist...</p>
  </Layout>
);

export default NotFound;
